import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  MouseEvent,
  useState,
  useRef,
} from "react";
import styles from "./MBMComponents.module.scss";
import validInputIcon from "../../assets/check-mark-icon.svg";
import Switch from "react-switch";

export interface VerificationProps {
  type: HTMLInputTypeAttribute;
  min?: number;
  max?: number;
  integer?: boolean;
  maxLength?: number;
}

// // Old verifyFunction, new one right below...

// export const verifyFunction = (
//   value: string | number,
//   attr: VerificationProps,
//   oldValue: string
// ): string => {
//   const type = attr.type;
//   if (type === "checkbox") {
//     return value.toString();
//   }
//   if (type === "text") {
//     const { maxLength } = attr;
//     if (maxLength !== undefined) {
//       value = value.toString().slice(0, maxLength);
//     }
//   }
//   if (type === "number") {
//     if (value === "") {
//       return "";
//     }

//     const validNumber = new RegExp(/^\d*(\.|,)?\d*$/);
//     if (!validNumber.test(value.toString())) {
//       // value = oldValue;
//       return oldValue;
//     }
//     value = value.toString().replaceAll(",", ".");
//     let lastIsDot = false;
//     let zeroAmounts = 0;
//     if (value.includes(".")) {
//       for (let i = 0; i < value.length; i++) {
//         if (value.at(-i - 1) === "0") {
//           zeroAmounts++;
//         } else if (value.at(-i - 1) === ".") {
//           lastIsDot = true;
//           break;
//         } else {
//           break;
//         }
//       }
//     }
//     const { min, max, integer } = attr;
//     let tValue = parseFloat(value);
//     tValue = isNaN(tValue) ? 0 : tValue;
//     if (min !== undefined) {
//       tValue = Math.max(tValue, min);
//     }
//     if (max !== undefined) {
//       tValue = Math.min(tValue, max);
//     }
//     if (Boolean(integer)) {
//       tValue = parseInt(tValue.toString());
//     }
//     value =
//       tValue.toString().replaceAll(".", ",") +
//       (lastIsDot && !Boolean(integer) ? "," : "") +
//       new Array(zeroAmounts).fill("0").join("");
//   }
//   return value.toString();
// };

interface ValidProps {
  isValid: boolean;
  errors: Array<string>;
}
export const verifyFunction = (
  value: string | number,
  attr: VerificationProps
): ValidProps => {
  const type = attr.type;
  const theReturn: ValidProps = { isValid: true, errors: [] };
  if (!["text", "number"].includes(type)) {
    return theReturn;
  }
  if (type === "text") {
    const { maxLength } = attr;
    if (maxLength !== undefined && maxLength < value.toString().length) {
      theReturn.isValid = false;
      theReturn.errors.push("Maximum of " + maxLength + " characters.");
    }
  }
  if (type === "number") {
    if (value === "") {
    } else {
      const validNumber = new RegExp(/^\d*(\.|,)?\d*$/);
      if (!validNumber.test(value.toString())) {
        theReturn.isValid = false;
        theReturn.errors.push("Not a valid number.");
      }
      const testValue = value.toString().replaceAll(",", ".");
      const { min, max, integer } = attr;
      let tValue = parseFloat(testValue);
      tValue = isNaN(tValue) ? 0 : tValue;
      if (min !== undefined && tValue < min) {
        theReturn.isValid = false;
        theReturn.errors.push("Minimum value is " + min);
      }
      if (max !== undefined && tValue > max) {
        theReturn.isValid = false;
        theReturn.errors.push("Maximum value is " + max);
      }
      if (integer === true && tValue !== Math.round(tValue)) {
        theReturn.isValid = false;
        theReturn.errors.push("Decimal not allowed.");
      }
    }
  }
  return theReturn;
};
export const postMessage = (
  id: number,
  value: string | number,
  verifyProps: VerificationProps
): boolean => {
  const validP = verifyFunction(value, verifyProps);

  if (validP.isValid) {
    window.parent.postMessage({ id, value, whatEvent: "submit" }, "*");
    return true;
  } else {
    return false;
  }
};
interface onChangeFunc {
  (value?: string | number, checked?: boolean): void;
}
interface onClickFunc {
  (attr: MouseEvent<HTMLInputElement>): void;
}
export interface inputParameters {
  inputRef: React.RefObject<HTMLInputElement>;
  onChangeFunc?: (e: ChangeEvent<HTMLInputElement>) => string;
  lastInFocus?: number;
  verifyParams: VerificationProps;
}
interface onFocusFunc {
  (inputParameters: inputParameters): void;
}
interface InputProps {
  id: number;
  defaultValue?: string;
  defaultChecked?: boolean;
  // type: HTMLInputTypeAttribute;
  verifyParams: VerificationProps;
  onChange?: onChangeFunc;
  onClick?: onClickFunc;
  onFocus?: onFocusFunc;
  onBlur?: onFocusFunc;
  disabled?: boolean;
  inputDisabled?: boolean;
  isValid?: boolean;
  submitButton?: string;
}
export default function TextInput({
  id,
  defaultValue,
  defaultChecked,
  verifyParams,
  onChange,
  onClick,
  onFocus,
  onBlur,
  disabled,
  inputDisabled,
  isValid,
  submitButton,
}: // onBlur,
InputProps) {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [currentChecked, setCurrentChecked] = useState(defaultChecked ?? false);
  const inputRef = useRef(null);

  const type = verifyParams.type;

  const onChangeFunc = (value: string | number, checked?: boolean) => {
    const result = value.toString();
    setCurrentValue(result);
    if (onChange) onChange(result, checked);
    return result;
  };

  const validParams = verifyFunction(currentValue ?? "", verifyParams);

  return (
    <div className={styles.component}>
      <div
        className={`${styles["input-wrap"]} ${
          type === "button" ? styles["equal-button"] : ""
        }`}
      >
        {!["checkbox"].includes(type) && (
          <input
            ref={inputRef}
            className={!validParams.isValid ? styles.invalid : ""}
            id={"input" + id}
            data-id={id}
            // lang={"dk"}
            // defaultValue={defaultValue}
            // defaultChecked={defaultChecked}
            value={currentValue}
            // checked={currentChecked}
            type={type && type !== "number" ? type : "text"}
            onChange={(e) => {
              onChangeFunc(e.target.value, e.target.checked);
            }}
            onClick={(e) => {
              // if (e.type === "checkbox") {
              //   setCurrentChecked(!currentChecked);
              // }
              if (onClick) onClick(e);
              onFocus &&
                onFocus({
                  onChangeFunc: (e) => onChangeFunc(e.target.value ?? ""),
                  inputRef,
                  lastInFocus: new Date().getTime(),
                  verifyParams,
                });
            }}
            onFocus={(e) => {
              if (type === "number") {
                // e.currentTarget.select();
              }
              // if (onFocus !== undefined)
              //   onFocus({
              //     onChangeFunc: (e) => onChangeFunc(e.target.value ?? ""),
              //     type,
              //     inputRef,
              //     lastInFocus: new Date().getTime(),
              //   });
            }}
            onBlur={() => {
              if (onBlur !== undefined)
                onBlur({
                  inputRef,
                  lastInFocus: new Date().getTime(),
                  verifyParams,
                });
            }}
            disabled={disabled || inputDisabled}
          />
        )}
        {type === "checkbox" && (
          <Switch
            checked={currentChecked}
            onChange={(e) => {
              console.log("Happening!", e);
              onChangeFunc("", e);
              setCurrentChecked(e);
            }}
          />
        )}
        {isValid !== undefined && (
          <div
            className={
              `${styles["valid-input"]}` +
              (isValid ? ` ${styles["is-valid"]}` : "")
            }
          >
            <img alt="checkmark" src={validInputIcon} />
          </div>
        )}
      </div>
      {submitButton !== undefined && (
        <div
          className={`${styles["input-wrap"]} ${styles["submit-button"]} ${
            type === "button" ? styles["equal-button"] : ""
          }`}
        >
          <input
            type="button"
            // className={styles["submit-button"]}
            onClick={() => {
              if (typeof currentValue === "string") {
                const isValid = verifyFunction(
                  currentValue ?? "",
                  verifyParams
                ).isValid;
                if (isValid) {
                  window.parent.postMessage(
                    {
                      id,
                      whatEvent: "submitClick",
                      value: currentValue,
                    },
                    "*"
                  );
                }
              }
            }}
            disabled={disabled}
            value={submitButton}
          />
        </div>
      )}
      <div className={styles.errors}>
        {validParams.errors.map((e, i) => {
          return (
            i === 0 && (
              <div key={e} className={styles.error}>
                {e}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
