import { useCallback } from "react";
import TextInput, {
  inputParameters,
  postMessage,
  verifyFunction,
} from "./components/MBMComponents/MBMComponents";
import { IncomeData, IncomeDataSection } from "./IncomeDataInterfaces";
import styles from "./tableLayout.module.scss";

interface props {
  data: IncomeData;
  sectionIndex: number;
  setSectionIndex: React.Dispatch<React.SetStateAction<number>>;
  inputRef: React.RefObject<HTMLInputElement> | null;
  // setInputRef: (input: RefObject<HTMLInputElement> | null) => void;
  // setOnChangeRef: (
  //   e: ((f: ChangeEvent<HTMLInputElement>) => void) | null
  // ) => void;
  setInputParams: (params: inputParameters) => void;
  idWithError: number | undefined;
  setIdWithError: (param: number | undefined) => void;
}
export default function SectionsMenu({
  data,
  sectionIndex,
  setSectionIndex,
  setInputParams,
  idWithError,
  setIdWithError,
}: props) {
  const incomeData = data;

  const changeValue = useCallback(
    (
      keys: Array<string> | string,
      value: string | number | boolean,
      type: string
    ) => {
      if (typeof keys === "string") {
        keys = keys.split(".");
      }

      let d = incomeData?.sections[+keys[0]];
      keys.splice(0, 1);

      for (let i = 0; i < keys.length; i++) {
        if (i < keys.length - 1) {
          d = d.value?.[+keys[i]] as IncomeDataSection;
        } else {
          d = d.value?.[+keys[i]] as IncomeDataSection;
          switch (type) {
            case "checkbox":
              d.checked = Boolean(value);
              break;
            default:
              d.value = value.toString();
              break;
          }
        }
      }

      // setIncomeData({...incomeData});
    },
    [incomeData]
  );

  const printOutSection = useCallback(
    (e: IncomeDataSection, level: number, deptIndexes: string = "") => {
      let className = "";

      switch (level) {
        case 0:
          className = styles.row;
          break;
        case 1:
          className = styles.column;
          break;
        case 2:
          className = styles.sub;
          break;
        default:
          break;
      }

      const verifyParams = { type: e.type ?? "text", ...e.verify };
      if (e.id === 780) console.log(e.label);
      return (
        <div key={e.id} className={`${styles["section-menu"]}`}>
          {e.title && level !== 0 && <h2>{e.title}</h2>}
          {Array.isArray(e.value) ? (
            <div key={e.id} className={className}>
              {e.value.map((d, i) =>
                printOutSection(d, level + 1, `${deptIndexes}.${i}`)
              )}
            </div>
          ) : (
            <div key={e.id} className={styles.item}>
              <label
                className={
                  e.type !== "hidden" && e.label !== undefined
                    ? styles["leading-dots"]
                    : ""
                }
              >
                <div className={styles.label}>{e.label}</div>
                <TextInput
                  id={e.id}
                  defaultValue={e.value}
                  defaultChecked={e.checked}
                  // type={e.type ?? "text"}
                  verifyParams={verifyParams}
                  onChange={(value, checked) => {
                    if (
                      verifyFunction(value ?? "", verifyParams).isValid ===
                      false
                    ) {
                      setIdWithError(e.id);
                    } else {
                      setIdWithError(undefined);
                    }
                    if (e.type === "checkbox") {
                      window.parent.postMessage(
                        { id: e.id, value, checked, whatEvent: "change" },
                        "*"
                      );
                    }
                    changeValue(
                      sectionIndex.toString() + deptIndexes,
                      (e.type === "checkbox" ? checked : value) ?? "",
                      e.type as string
                    );
                  }}
                  onClick={(click) => {
                    window.parent.postMessage(
                      { id: e.id, whatEvent: "click" },
                      "*"
                    );
                  }}
                  onFocus={(inputParams) => {
                    setInputParams(inputParams);
                  }}
                  onBlur={(param) => {
                    // window.parent.postMessage(
                    //   {
                    //     id: e.id,
                    //     value: param.inputRef.current?.value,
                    //     checked: param.inputRef.current?.checked,
                    //     whatEvent: "blur",
                    //   },
                    //   "*"
                    // );

                    if (e.type !== "checkbox") {
                      postMessage(
                        e.id,
                        param.inputRef.current?.value ?? "",
                        verifyParams
                      );
                    }
                  }}
                  disabled={
                    (idWithError !== undefined && idWithError !== e.id) ||
                    e.disabled === true
                  }
                  inputDisabled={e.inputDisabled === true}
                  isValid={e.isValid}
                  submitButton={e.submitButton}
                />
              </label>
              {/* {e.submitButton !== undefined && (
                <button
                  className={styles["submit-button"]}
                  onClick={() => {
                    if (typeof e.value === "string") {
                      const isValid = verifyFunction(
                        e.value ?? "",
                        verifyParams
                      ).isValid;
                      if (isValid && idWithError === undefined) {
                        window.parent.postMessage(
                          {
                            id: e.id,
                            whatEvent: "submitClick",
                            value: e.value,
                          },
                          "*"
                        );
                      }
                    }
                  }}
                  disabled={idWithError !== undefined}
                >
                  {e.submitButton}
                </button>
              )} */}
            </div>
          )}
        </div>
      );
    },
    [sectionIndex, changeValue, /*setInputRef, setOnChangeRef,*/ setInputParams]
  );

  return (
    <div className="sections-menu">
      <div className={styles.header}>
        {/* <h1>{incomeData.title}</h1> */}
        <h1>{incomeData.sections[sectionIndex].title}</h1>
        <div className={styles["right-side"]}>
          {incomeData.sections?.map((s, i) => {
            return (
              <button
                className={sectionIndex === i ? styles.active : ""}
                onClick={() => {
                  window.parent.postMessage(
                    {
                      id: s.id,
                      value: s.value,
                      whatEvent: "changeSection",
                      index: i,
                    },
                    "*"
                  );
                  setSectionIndex(i);
                }}
                key={s.id + " " + i}
                disabled={idWithError !== undefined}
              >
                {s.buttonValue}
              </button>
            );
          })}
        </div>
      </div>
      <div className={styles["table-layout"]}>
        {incomeData.sections !== undefined && (
          <div className={styles.table}>
            {printOutSection(incomeData.sections[sectionIndex], 0)}
          </div>
        )}
      </div>
    </div>
  );
}
