import {
  FunctionComponent,
  useState,
  MutableRefObject,
  useEffect,
} from "react";
import Keyboard, { KeyboardReactInterface } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

interface IProps {
  onChange: (input: string, e: MouseEvent | undefined) => void;
  onKeyPress: (input: string) => void;
  keyboardRef: MutableRefObject<KeyboardReactInterface>;
  layoutName?: string;
  nextElement?: () => void;
  setHidden?: (bool: boolean) => void;
}

const KeyboardWrapper: FunctionComponent<IProps> = ({
  onChange,
  onKeyPress: onKeyPressFunc,
  keyboardRef,
  layoutName: lName = "default",
  nextElement,
  setHidden,
}) => {
  const [layoutName, setLayoutName] = useState(lName);
  // const [shiftPressed, setShiftPressed] = useState(false);

  // useEffect(() => {
  //   if (shiftPressed) {
  //     setLayoutName("shift");
  //   } else {
  //     setLayoutName(lName);
  //   }
  // }, [shiftPressed, lName]);

  useEffect(() => {
    setLayoutName(lName);
  }, [lName]);

  const onKeyPress = (button: string) => {
    if (["{lock}", "{shift}"].includes(button)) {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
    // if (button === "{shift}") {
    //   setShiftPressed(!shiftPressed);
    // } else {
    //   setShiftPressed(false);
    // }
    if (["{tab}"].includes(button)) {
      nextElement && nextElement();
    }
    if (["{enter}"].includes(button)) {
      // setHidden && setHidden(true);
    }

    onKeyPressFunc(button);
  };

  return (
    <Keyboard
      keyboardRef={(r) => (keyboardRef.current = r)}
      layoutName={layoutName}
      onChange={(e, f) => {
        f?.preventDefault();
        if (!e.includes("\t")) {
          onChange(e, f);
        }
      }}
      onKeyPress={onKeyPress}
      //   onRender={() => console.log("Rendered")}
      layout={{
        default: [
          "{esc} 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
          "q w e r t y u i o p [ ] \\",
          "a s d f g h j k l ; ' {enter}",
          "{shift} z x c v b n m , . / {shift}",
          "{space}",
        ],
        shift: [
          "{esc} ~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}",
          "Q W E R T Y U I O P { } |",
          'A S D F G H J K L : " {enter}',
          "{shift} Z X C V B N M &lt; &gt; ? {shift}",
          "{space}",
        ],
        numpad: ["{esc} {bksp}", "1 2 3", "4 5 6", "7 8 9", ", 0 {enter}"],
      }}
      buttonTheme={[
        { class: "red", buttons: "{esc}" },
        { class: "tab-button", buttons: "{tab}" },
        { class: "enter-button", buttons: "{enter}" },
      ]}
      onChangeAll={(e, f) => {
        f?.preventDefault();
      }}
    />
  );
};

export default KeyboardWrapper;
