import Draggable from "react-draggable";
import {
  useRef,
  useEffect,
  useState,
  RefObject,
  ChangeEvent,
  useLayoutEffect,
  MutableRefObject,
} from "react";
import "react-simple-keyboard/build/css/index.css";
import KeyboardWrapper from "./KeyboardWrapper";
import { KeyboardReactInterface } from "react-simple-keyboard";
import {
  VerificationProps,
  postMessage,
} from "./components/MBMComponents/MBMComponents";

function usePreviousInput(
  value: HTMLInputElement | undefined
): HTMLInputElement | undefined {
  const ref = useRef<HTMLInputElement>();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

interface props {
  inputRef: RefObject<HTMLInputElement> | null;
  onChangeRef: (e: ChangeEvent<HTMLInputElement>) => string;
  // inputType: HTMLInputTypeAttribute;
  lastInFocus?: number;
  verifyParams: VerificationProps;
  idWithError: number | undefined;
}
// Doesn't work for solution yet.
export default function CustomKeyboard({
  inputRef,
  onChangeRef,
  // inputType,
  lastInFocus,
  verifyParams,
  idWithError,
}: props) {
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [layoutName, setLayoutName] = useState("default");
  const keyboardWrapRef = useRef<HTMLDivElement>(null);
  const keyboardRef = useRef<KeyboardReactInterface>(
    null
  ) as MutableRefObject<KeyboardReactInterface>;
  // const keyboardRef = useRef<any>(null);
  const [hidden, setHidden] = useState(true);
  const [cursorPosition, setCursorPosition] = useState(0);
  const showKeyboard =
    // inputRef &&
    // inputRef.current &&
    // ["text", "number"].includes(inputRef.current.type) &&
    !hidden;

  const prevInput = usePreviousInput(inputRef?.current ?? undefined);

  useLayoutEffect(() => {
    setScreenSize({
      width:
        window.document.body.clientWidth -
        (keyboardWrapRef.current?.clientWidth ?? 0),
      height:
        window.document.body.clientHeight -
        (keyboardWrapRef.current?.clientHeight ?? 0),
    });
  }, [
    keyboardWrapRef.current?.clientWidth,
    keyboardWrapRef.current?.clientHeight,
  ]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      if (keyboardRef && keyboardRef.current) {
        // keyboardRef.current.value = inputRef.current.value;
        const inputElement = inputRef.current;
        // inputElement inputElement.selectionStart
        keyboardRef.current.setInput(inputElement.value);
        if (verifyParams.min !== undefined || verifyParams.max !== undefined) {
          keyboardRef.current.setCaretPosition(0, inputElement.value.length);
          inputElement.select();
        } else {
          keyboardRef.current.setCaretPosition(
            inputElement.selectionStart,
            inputElement.selectionEnd
          );
        }
        setCursorPosition(inputElement.selectionStart ?? 0);

        if (verifyParams.type === "number") {
          setLayoutName("numpad");
        } else {
          setLayoutName("default");
        }

        // // Alternative not found.
        // if (
        //   ![
        //     "text",
        //     "password",
        //     "search",
        //     "tel",
        //     "url",
        //     "week",
        //     "month",
        //     "textarea",
        //   ].includes(inputType)
        // ) {
        // }

        setHidden(!["text", "number"].includes(verifyParams.type));
      }
    }
  }, [inputRef, verifyParams, lastInFocus]);

  useEffect(() => {
    if (prevInput !== inputRef?.current) {
      setCursorPosition(inputRef?.current?.selectionStart ?? 0);
    } else if (cursorPosition) {
      if (inputRef?.current && inputRef?.current.type === "text") {
        inputRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
        // inputRef.current.selectionStart = inputRef.current.selectionEnd =
        //   cursorPosition;
        // inputRef.current.dispatchEvent(new Event("input"));

        const text = inputRef.current.value.substring(0, cursorPosition);
        const tmpDiv = document.createElement("div");
        tmpDiv.textContent = text;
        tmpDiv.style.width = "1px";
        tmpDiv.style.overflow = "scroll";
        tmpDiv.style.whiteSpace = "nowrap";
        document.body.appendChild(tmpDiv);
        const scrollWidth = tmpDiv.scrollWidth;
        document.body.removeChild(tmpDiv);
        inputRef.current.scrollLeft =
          scrollWidth -
          // +inputRef.current.clientWidth
          25;

        // inputRef.current.scrollLeft =
      }
    }
  }, [cursorPosition, inputRef, prevInput]);

  const nextElement = () => {
    const inputs = Array.from(document.querySelectorAll("input"));
    const index = inputs.findIndex((e) => {
      return e === inputRef?.current;
    });
    inputs.at((index + 1) % inputs.length)?.focus();
  };

  return (
    <Draggable
      // key={keyboardRef ? "i" : "o"}
      handle=".handle"
      bounds={{
        top: 0,
        left: 0,
        bottom: screenSize.height,
        right: screenSize.width,
      }}
      defaultPosition={{
        x: window.document.body.clientWidth / 2 - 350,
        y: window.document.body.clientHeight - 350,
      }}
      onStart={(e) => {
        e.preventDefault();
      }}
    >
      <div
        ref={keyboardWrapRef}
        className="keyboard-wrap"
        style={{ display: showKeyboard ? "block" : "none" }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className="handle">
          <div className="line line1"></div>
          <div className="line line2"></div>
          <div className="line line3"></div>
        </div>
        <KeyboardWrapper
          keyboardRef={keyboardRef}
          layoutName={layoutName}
          onKeyPress={(char) => {
            if (char === "{esc}") {
              setHidden(true);
            }
            if (char === "{enter}") {
              // const stringId: string | undefined | null =
              //   inputRef?.current?.getAttribute("data-id");
              // if (!!stringId && !isNaN(parseInt(stringId))) {
              //   const id = parseInt(stringId);
              //   postMessage(id, inputRef?.current?.value ?? "", verifyParams);
              // }
              setHidden(idWithError === undefined);
            }
          }}
          onChange={(text, e) => {
            const inputElement = inputRef?.current;

            if (
              inputElement &&
              ["text", "number"].includes(inputElement.type)
            ) {
              const newInputElement = document.createElement("input");
              newInputElement.onchange = (e) => {
                e.preventDefault();
              };
              // newInputElement.type = inputElement.type;
              newInputElement.value = text;

              const inputChangeEvent: ChangeEvent<HTMLInputElement> = {
                target: newInputElement,
                currentTarget: newInputElement,
                eventPhase: 2,
                bubbles: true,
                cancelable: true,
                defaultPrevented: false,
                isTrusted: false,
                nativeEvent: new Event("input"),
                persist: () => {},
                preventDefault: () => {},
                isDefaultPrevented: () => false,
                stopPropagation: () => {},
                isPropagationStopped: () => false,
                type: "change",
                timeStamp: Date.now(),
              };

              const result = onChangeRef(inputChangeEvent);
              keyboardRef.current.setInput(result);

              // get the current cursor position and increment to the next position
              let cursorNextPosition = Math.max(
                cursorPosition +
                  text.length -
                  (inputElement?.value.length ?? text.length),
                1
              );
              setCursorPosition(cursorNextPosition);
              // Use Effector changes the value
            }
          }}
          // onKeyPress={(e) => onKeyPress}
          // inputName={InputContext.displayName}
          nextElement={nextElement}
          setHidden={setHidden}
        />
      </div>
    </Draggable>
  );
  // }
}
