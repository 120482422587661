import { useEffect, useState, RefObject, ChangeEvent } from "react";
import "./App.scss";
import SectionsMenu from "./SectionsMenu";
import { IncomeData } from "./IncomeDataInterfaces";
import CustomKeyboard from "./CustomKeyboard";
import { VerificationProps } from "./components/MBMComponents/MBMComponents";

function App() {
  const [incomeData, setIncomeData] = useState<IncomeData | undefined>(
    undefined
  );
  const [lastDataTime, setLastDataTime] = useState(0);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [inputRef, setInputRef] = useState<RefObject<HTMLInputElement> | null>(
    null
  );
  const [idWithError, setIdWithError] = useState<number | undefined>(undefined);
  const [onChangeRef, setOnChangeRef] = useState<
    (e: ChangeEvent<HTMLInputElement>) => string
  >((e: ChangeEvent<HTMLInputElement>) => {
    return "";
  });

  const [showKeyboard, setShowKeyboard] = useState(false);
  const [sK, ssK] = useState(false);
  useEffect(() => {
    ssK(showKeyboard);
  }, [showKeyboard]);
  const [lastInFocus, setLastInFocus] = useState<number | undefined>(0);
  const [verifyParams, setVerifyParams] = useState<VerificationProps>({
    type: "",
  });

  useEffect(() => {
    window.addEventListener("message", (e) => {
      console.log("Data that came in:", e.data?.data);

      if (e.data?.data !== undefined) {
        setShowKeyboard(Boolean(e.data.data.keyboard));
        if (e.data?.data?.sections !== undefined) {
          setIncomeData(e.data.data);
          setLastDataTime(new Date().getTime());
          if (e.data.data.sectionIndex !== undefined) {
            setSectionIndex(e.data.data.sectionIndex);
          }
        } else {
          console.log(
            "No update because income 'data->sections' parameter was undefined"
          );
        }
      } else {
        console.log("No update because income 'data' parameter was undefined");
      }
    });
  }, []);

  useEffect(() => {
    // let interval = undefined;
    if (incomeData?.time !== undefined) {
      var interval = setInterval(() => {
        window.parent.postMessage(
          { whatEvent: "time", time: incomeData.time },
          "*"
        );
      }, incomeData.time * 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [incomeData]);

  if (incomeData === undefined) return <div>Loading</div>;

  return (
    <div className="app">
      <SectionsMenu
        data={incomeData}
        key={lastDataTime}
        sectionIndex={sectionIndex}
        setSectionIndex={setSectionIndex}
        inputRef={inputRef}
        idWithError={idWithError}
        setIdWithError={setIdWithError}
        // setInputRef={(input) => {
        //   setInputRef(input);
        // }}
        // setOnChangeRef={(e) => {
        //   if (!!e) {
        //     setOnChangeRef(() => e);
        //   }
        // }}
        setInputParams={(params) => {
          setInputRef(params.inputRef);
          const func = params.onChangeFunc;
          if (!!func) {
            setOnChangeRef(() => func);
          }
          setLastInFocus(params.lastInFocus);
          setVerifyParams(params.verifyParams);
        }}
      />
      {sK && (
        <div className="this-is-fixed">
          <CustomKeyboard
            verifyParams={verifyParams}
            lastInFocus={lastInFocus}
            inputRef={inputRef}
            // inputType={inputType}
            onChangeRef={onChangeRef}
            idWithError={idWithError}
          />
        </div>
      )}
    </div>
  );
}

export default App;
